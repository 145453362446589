import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { setState, useLocationId } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { GetCurrentLocationQuery } from '~/types/__generated/gql/graphql';
import { validateSessionFromUrl } from '~/utils/validateSessionFromUrl';

const query = /* GraphQL */ `
	query GetCurrentLocation {
		getCurrentLocation {
			_id
			created_at
			updated_at
			venue
			name
			type
			payment_types
			status
			hash
			no_vat
			no_service_charge
			is_pay_later_allowed
			is_pay_now_allowed
			locations_groups_tags
			hidden_items_configs {
				items
				item_types
				categories
			}
		}
	}
`;

export type GetCurrentLocationResults = GetCurrentLocationQuery['getCurrentLocation'];

export const getCurrentLocationQueryKey = () => ['location'];

export const useGetCurrentLocation = (enabled?) => {
	const locationId = useLocationId();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getCurrentLocationQueryKey(),
		queryFn: () => {
			const { isSessionExpired, isPathnameSessionSensitive } = validateSessionFromUrl();

			if ((isPathnameSessionSensitive() && isSessionExpired()) || !locationId)
				return Promise.resolve(undefined);

			return hubGqlClient.request(graphql(query)).then((res) => res.getCurrentLocation);
		},
		onSuccess: (data) => {
			if (!data) return;

			setState({ locationId: data._id, venueId: data.venue });
		},
	});
};

export const useGetCurrentLocationCache = () => {
	return useGetCurrentLocation(false);
};

export const getCurrentLocationCache = () => {
	return window.$queryClient?.getQueryData<GetCurrentLocationResults>(getCurrentLocationQueryKey());
};
