import { Alert, Snackbar } from '@mui/material';

import { useError, useSetError } from '~/store';

const ErrorAlert = () => {
	const error = useError();
	const setError = useSetError();

	const onClose = () => {
		setError(null);
	};

	if (!error) return null;

	return (
		<Snackbar
			open
			autoHideDuration={3000}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			onClose={onClose}
		>
			<Alert onClose={onClose} severity="error">
				{error}
			</Alert>
		</Snackbar>
	);
};

export { ErrorAlert };
