import { Styled } from './HueSlider.styled';

import type { SliderProps } from '@mui/material/Slider';

type HueSliderProps = SliderProps;

const HueSlider = (props: HueSliderProps) => {
	const { className, ...restSliderProps } = props;

	return <Styled.Slider className={`MuiColorInput-HueSlider ${className || ''}`} {...restSliderProps} />;
};

export default HueSlider;
