import { Chip, CircularProgress, Stack } from '@mui/material';

const isProduction = process.env.ENV === 'prod';

const SplashScreen = () => {
	return (
		<Stack
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
			}}
		>
			<img src="/images/favicon.png" width="200px" />
			<CircularProgress />
			<Chip label={[process.env.VERSION, !isProduction && process.env.ENV].filter(Boolean).join('-')} />
		</Stack>
	);
};

export { SplashScreen };
