import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Snackbar, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

import { messaging } from '~/libs/firebase';
import { hubGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';

const queryUpdateCurrentDevice = /* GraphQL */ `
	mutation UpdateCurrentDevice($input: UpdateCurrentDeviceInput!) {
		updateCurrentDevice(input: $input) {
			_id
		}
	}
`;

const useUpdateCurrentDeviceToken = () => {
	return useMutation({
		mutationFn: async () => {
			const token = await getToken(messaging, { vapidKey: process.env.FB_VAPID_KEY });
			return hubGqlClient
				.request(graphql(queryUpdateCurrentDevice), { input: { fcm_token: token } })
				.then((res) => res.updateCurrentDevice);
		},
	});
};

const PushNotification = () => {
	const { value: initMessaging, setValue: setInitMessaging } = useBoolean(false);
	const { value: showRequest, toggle: toggleShowRequest, setValue: setShowRequest } = useBoolean(false);
	const updateCurrentDeviceToken = useUpdateCurrentDeviceToken();

	useEffect(() => {
		async function start() {
			const isBrowserSupported = await isSupported();
			if (!isBrowserSupported) return;

			setShowRequest(Notification.permission === 'default');

			if (Notification.permission === 'granted') {
				await updateCurrentDeviceToken.mutateAsync();
				setInitMessaging(true);
			}
		}

		start();
	}, []);

	useEffect(() => {
		if (initMessaging) {
			onMessage(messaging, (payload) => {
				console.log('[] Received foreground message ', payload);
			});
		}
	}, [initMessaging]);

	const handleRequestPermission = async () => {
		await updateCurrentDeviceToken.mutateAsync();
		setInitMessaging(true);
		toggleShowRequest();
	};

	if (showRequest)
		return (
			<Snackbar open autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Paper
					variant="elevation"
					elevation={12}
					sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}
				>
					<Typography variant="body2">Get notified about your order status in realtime</Typography>
					<Box display="flex" justifyContent="flex-end" gap={1}>
						<Button
							variant="outlined"
							color="inherit"
							disabled={updateCurrentDeviceToken.isLoading}
							onClick={toggleShowRequest}
						>
							Dismiss
						</Button>
						<LoadingButton loading={updateCurrentDeviceToken.isLoading} onClick={handleRequestPermission}>
							Allow notification
						</LoadingButton>
					</Box>
				</Paper>
			</Snackbar>
		);

	return null;
};

export { PushNotification };
