import { Client } from 'graphql-ws';
import Router from 'next/router';

import { setState } from '~/store';
import { Data_Event_Type, SubscribeToCurrentVenueSubscription } from '~/types/__generated/gql/graphql';
import { GetCurrentVenueResults, getCurrentVenueQueryKey } from '../useGetCurrentVenue';

const query = /* GraphQL */ `
	subscription SubscribeToCurrentVenue {
		subscribeToCurrentVenue {
			type
			id
			payload {
				_id
				created_at
				updated_at
				name
				description
				address
				timezone
				logo
				banner_img
				is_self_served
				allow_comments
				item_types
				cancelled_reason_options
				custom_payment_types {
					label
					value
					is_deactivated
				}
				shifts {
					title
					startTime
					endTime
				}
				auto_print_checklist
				is_print_ticket_by_item
				currencies_configs {
					code
					rate
					previous_rate
					is_primary_currency
					is_secondary_currency
					is_deactivated
				}
				fcm_tokens
				is_service_charge_buried
				service_charge
				is_vat_buried
				vat
				theme {
					primary_color
					primary_text_color
				}
				is_ordering_requires_customer
				is_qr_ordering_only
				custom_note_instructions
				is_ordering_requires_note
				is_payway_integration_enabled
				is_internal_receipt_enabled
				is_payment_confirmation_enabled
				is_payment_rounding_enabled
			}
		}
	}
`;

export const subscribeToCurrentVenue = async (client: Client) => {
	const subscription = client.iterate<SubscribeToCurrentVenueSubscription>({ query });
	for await (const result of subscription) {
		if (
			result.data?.subscribeToCurrentVenue?.type !== Data_Event_Type.Delete &&
			result.data?.subscribeToCurrentVenue?.payload
		) {
			const payload = result.data.subscribeToCurrentVenue.payload;

			window.$queryClient?.setQueryData<GetCurrentVenueResults>(getCurrentVenueQueryKey(), (prev) => {
				// A hack to check if the location is updated, because this is also triggered when order is created
				const isUpdated =
					!prev ||
					Object.keys(payload).some(
						(key) => JSON.stringify(payload[key] || {}) !== JSON.stringify(prev[key] || {})
					);
				if (isUpdated) {
					setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
					Router.replace({ pathname: '/menu', query: Router.query });
				}

				return payload;
			});
		}
	}
};
