import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { setState, useLocationId } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { GetActiveMenuForQrQuery } from '~/types/__generated/gql/graphql';
import { validateSessionFromUrl } from '~/utils/validateSessionFromUrl';

const query = /* GraphQL */ `
	query GetActiveMenuForQr {
		getActiveMenuForQr {
			_id
			created_at
			updated_at
			name
			description
			banner_img
			enabled
			venue
			_qr_categories {
				_id
				created_at
				updated_at
				title
				is_template
				clone_from
				ordering_index
				deleted_at
				menu
				venue
				_qr_items {
					_id
					created_at
					updated_at
					title
					description
					banner_img
					is_qr_enabled
					is_pos_enabled
					type
					prep_time
					is_template
					clone_from
					ordering_index
					deleted_at
					extra_quantity
					printer_tag
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					category
					menu
					venue
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						ordering_index
						_option {
							_id
							created_at
							updated_at
							title
							is_template
							clone_from
							deleted_at
							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							menu
							venue
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
						}
					}
					option_groups {
						is_required
						minimum_select
						maximum_select
						ordering_index
						_option_group {
							_id
							title
							options
							clone_from
							_options {
								_id
								created_at
								updated_at
								title
								is_template
								clone_from
								deleted_at
								original_price
								original_price_addons {
									vat {
										percentage
										amount
									}
									service_charge {
										percentage
										amount
									}
								}
								listed_price
								menu
								venue
								recipe {
									raw_material
									unit_of_measurement
									consumed_unit_of_measurement
									consumed_quantity
								}
							}
						}
					}
				}
			}
		}
	}
`;

export type GetActiveMenuForQrResults = GetActiveMenuForQrQuery['getActiveMenuForQr'];

export const getActiveMenuForQrQueryKey = () => ['menu'];

export const useGetActiveMenuForQr = (enabled?) => {
	const locationId = useLocationId();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getActiveMenuForQrQueryKey(),
		queryFn: () => {
			const { isSessionExpired, isPathnameSessionSensitive } = validateSessionFromUrl();

			if ((isPathnameSessionSensitive() && isSessionExpired()) || !locationId)
				return Promise.resolve(undefined);

			return hubGqlClient.request(graphql(query)).then((res) => res.getActiveMenuForQr);
		},
		onSuccess: (data) => {
			if (!data) return;

			setState({ menuId: data._id });
		},
	});
};

export const useGetActiveMenuForQrCache = () => {
	return useGetActiveMenuForQr(false);
};
