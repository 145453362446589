import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { AppProps } from 'next/app';
import Router from 'next/router';
import { useEffect } from 'react';
import { useBoolean, useEffectOnce, useEventListener } from 'usehooks-ts';

import { ErrorBoundary, SplashScreen } from '@served/ui-comps';

import { AppUpdateChecker } from '~/components/AppUpdateChecker';
import { AssistanceDialog } from '~/components/AssistanceDialog';
import { ErrorAlert } from '~/components/ErrorAlert';
import { InfoAlert } from '~/components/InfoAlert';
import { OfflineDialog } from '~/components/OfflineDialog';
import { PushNotification } from '~/components/PushNotification';
import { SessionTrackerDialog } from '~/components/SessionTrackerDialog';
import { useRouterEvent } from '~/libs/google-analytics';
import { AppProvider } from '~/providers/AppProvider';
import { QueryProvider } from '~/providers/QueryProvider';
import '~/store';
import { useIsReady, useResetApp } from '~/store';
import { AppEnvChecker } from '~/components/AppEnvChecker';

export default function MyApp({ Component, pageProps }: AppProps) {
	const { value: isMounted, setTrue: setMounted } = useBoolean(false);
	const resetApp = useResetApp();

	useEffectOnce(() => {
		setMounted();
	});

	useEventListener('unload', () => {
		resetApp();
		window.$queryClient?.clear?.();
	});

	useEffect(() => {
		const onReceiveServiceWorkerMessage = (event: MessageEvent) => {
			if (!event.data.action) return;

			switch (event.data.action) {
				case 'redirect-from-notificationclick':
					Router.replace(event.data.url);
					break;
			}
		};

		if (navigator.serviceWorker)
			navigator.serviceWorker.addEventListener('message', onReceiveServiceWorkerMessage);

		return () => {
			if (navigator.serviceWorker)
				navigator.serviceWorker.removeEventListener('message', onReceiveServiceWorkerMessage);
		};
	}, []);

	useRouterEvent();

	const isReady = useIsReady();

	if (!isMounted) return null;

	return (
		<ErrorBoundary
			appName="CONSUMER APP"
			id="1239414865336991766"
			token="i8lKTnR7kUSCmzu2PpX56oiSFOnN6gfG_KpeCTbYP-Oz2OYZXyxoEBBKQ7YtUV3PE7-_"
		>
			<QueryProvider>
				<AppProvider>
					<AppEnvChecker />
					<OfflineDialog />
					<AssistanceDialog />
					<InfoAlert />
					<ErrorAlert />
					<SessionTrackerDialog />
					<AppUpdateChecker />
					<PushNotification />

					{isReady ? <Component {...pageProps} /> : <SplashScreen />}
				</AppProvider>
			</QueryProvider>
		</ErrorBoundary>
	);
}
