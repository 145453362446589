import { Alert, Snackbar } from '@mui/material';

import { useInfo, useSetInfo } from '~/store';

const InfoAlert = () => {
	const info = useInfo();
	const setInfo = useSetInfo();

	const handleClose = () => {
		setInfo(null);
	};

	if (!info) return null;

	return (
		<Snackbar
			open
			autoHideDuration={3000}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity="info">
				{info}
			</Alert>
		</Snackbar>
	);
};
export { InfoAlert };
