import React from 'react';

import { Styled } from './AlphaSlider.styled';

import type { RGB } from '@ctrl/tinycolor';
import type { SliderProps } from '@mui/material/Slider';

type AlphaSliderProps = SliderProps & {
	rgbColor: RGB;
};

const AlphaSlider = (props: AlphaSliderProps) => {
	const { rgbColor, style, className, ...restSliderProps } = props;

	const sliderStyle = {
		'--rgb-r': rgbColor.r,
		'--rgb-g': rgbColor.g,
		'--rgb-b': rgbColor.b,
		...style,
	} as React.CSSProperties;

	return (
		<Styled.Slider
			className={`MuiColorInput-AlphaSlider ${className || ''}`}
			style={sliderStyle}
			{...restSliderProps}
		/>
	);
};

export default AlphaSlider;
