import { useEffect } from 'react';
import { Client } from 'graphql-ws';

import { DefaultThemeProvider } from '@served/ui-comps';

import { useInitApp } from '~/hooks/useInitApp';
import { useGetCurrentVenueCache } from '~/queries/useGetCurrentVenue';
import { getHubGqlWsClient } from '~/libs/gql';
import { subscribeToSystemHealth } from '~/queries/useSubscribeToSystemHealth';
import { useLocationId, useMenuId, useOrderId, useOrderRef, useToken, useVenueId } from '~/store';
import { subscribeToCurrentVenue } from '~/queries/useSubscribeToCurrentVenue';
import { subscribeToLocation } from '~/queries/useSubscribeToLocation';
import { subscribeToMenu } from '~/queries/useSubscribeToCurrentMenu';
import { subscribeToOrder } from '~/queries/useSubscribeToOrder';
import { useGetOrderCache } from '~/queries/useGetOrder';
import { subscribeToOrderPaymentTypeAcceptEvent } from '~/queries/useSubscribeToOrderPaymentTypeAcceptEvent';

const AppProvider = ({ children }) => {
	useInitApp();
	useWsSubscriptions();

	const { data } = useGetCurrentVenueCache();

	return <DefaultThemeProvider venueTheme={data?.theme}>{children}</DefaultThemeProvider>;
};

export { AppProvider };

let client: Client | undefined;

const useWsSubscriptions = () => {
	const token = useToken();
	const venueId = useVenueId();
	const locationId = useLocationId();
	const menuId = useMenuId();
	const orderId = useOrderId();
	const orderRef = useOrderRef();
	const { data: orderCache } = useGetOrderCache();

	useEffect(() => {
		async function start() {
			if (client) {
				client.dispose();
				client.terminate();
			}

			client = getHubGqlWsClient();

			/**
			 * put all subscriptions here
			 * so that when params change, the client is re-initialized with all the subscriptions
			 */

			subscribeToSystemHealth(client);
			if (!!token && !!venueId) subscribeToCurrentVenue(client);
			if (!!token && !!locationId) subscribeToLocation(client, locationId);
			if (!!token && !!menuId) subscribeToMenu(client, menuId);
			if (orderId) subscribeToOrder(client, { orderId });
			if (orderRef) subscribeToOrder(client, { orderRef });
			if (orderId && orderCache && orderCache.is_needing_payment_confirmation)
				subscribeToOrderPaymentTypeAcceptEvent(client, orderId);
		}

		start();

		return () => {
			if (client) {
				client.dispose();
				client.terminate();
			}
		};
	}, [token, venueId, locationId, menuId, orderId, orderRef, orderCache]);
};
