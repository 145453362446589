import { Box, Typography } from '@mui/material';

const Error = ({
	src = '/images/500.webp',
	title = 'OOPS!',
	description = 'Something went wrong. Check back with us later.',
}) => {
	return (
		<Box
			data-testid="AppError"
			height="100vh"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			px={4}
			gap={2}
			textAlign="center"
		>
			<img src={src} width="250px" />
			<Typography variant="h6">{title}</Typography>
			<Typography variant="body2" color="GrayText">
				{description}
			</Typography>
		</Box>
	);
};

export { Error };
