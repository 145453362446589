import { Alert, Snackbar } from '@mui/material';

export const AppEnvChecker = () => {
	if (process.env.NODE_ENV === 'development' && process.env.ENV !== 'dev')
		return (
			<Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<Alert variant="filled" severity="error">
					<b>WARNING</b>: You&apos;re running this app in <b>{String(process.env.ENV || '').toUpperCase()}</b>
					&nbsp;mode.
				</Alert>
			</Snackbar>
		);

	return null;
};
